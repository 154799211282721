<template>
  <div class="oftenbuy" v-if='localData&&localData.length>0'>
    <div v-for='item,index in localData' :key='index' class="item" @click='linkDetail(item)'>
      <div class="img">
        <img :src="item.ImagePath | imgsrc(that)">
      </div>
      <div class="info">
        <h6 :title='item.ProductTypeName'>{{item.ProductTypeName}}</h6>
        <p>&yen;{{item.Price}}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'oftenbuy',
  data(){
    return {
      localData:null,//本地数据
      that:this,
    }
  },
  props: {
    popData: Array
  },
  methods:{
    linkDetail(item){
      // 跳转产品详情
      this._router({then:()=>{
        // this.$router.push({path:'predetail',query:{id:it.Id,idcx:'',detailType:1}})
        var id = item.ProductTypeId + '.html'
        this._open({path:'/predetail',query:{idcx:item.DynamicId?item.DynamicId:'',id:id,}})
      }})
    }
  },
  mounted(){
    this.$nextTick(()=>{
      this.localData = this.popData
    })
  },
  watch:{
    'popData'(n,o){
      this.localData  = n
    }
  },
  filters:{
    imgsrc(v,that){
      return that._src + v
    }
  }
}
</script>
<style  lang="scss" scoped>
.oftenbuy {
  background: #fff;padding-top:5px;
  .item {
    width: 260px;display:flex;align-items: center;height: 95px;margin:0 auto;cursor: pointer;justify-content: flex-start;
    .img {
      img {
        display: block;width: 80px;height: 80px;
      }
    }
    .info {
      margin-left:20px;
      h6 {
        font-size: 14px;color:#333;text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
      }
      p {
        color:#e51c23;font-size: 14px;margin-top:15px;
      }
    }
    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
