<template>
  <div class="usercenterhd">
    <el-row>
      <el-col :span="12" class='lt'>
        <div class="img">
          <div class="boxF">
            <div class="boxS">
                <div class="boxT" >
                  <div class="boxF boxF1">
                      <div class="boxS boxS1">
                          <div class="boxT boxT1" >
                            <img v-show='popData&&popData.WxDisplayUrl' :src="popData?popData.WxDisplayUrl:''">
                            <svg  v-show='!popData||!popData.WxDisplayUrl'><use xlink:href="#iconzhanghao"></use></svg>
                          </div>
                      </div>
                  </div>
                </div>
            </div>
          </div>
          <div class="name">
            <svg class="icon"><use xlink:href="#iconhuiyuan"></use></svg>
            <span>{{popData.customerlevelName}}</span>
          </div>
        </div>
        <div class="info">
          <h6>欢迎登录，{{popData.ContactName}}</h6>
          <p>上次登录时间：{{popData.LastLoginTime | timereset}}</p>
          <button @click='linkuserdata'>修改我的个人资料<svg class="icon"><use xlink:href="#iconziyuan12"></use></svg></button>
        </div>
      </el-col>
      <el-col :span="7" class='ct'>
        <div class="info">
          <h6>预存余额</h6>
          <p>&yen;{{popData.DepositBalance}}</p>
          <button @click='rechargelink'>充值<svg class="icon"><use xlink:href="#iconziyuan12"></use></svg></button>
        </div>
      </el-col>
      <el-col :span="5" class='rt'>
        <div class="info">
          <h6>优惠券</h6>
          <p>{{popData.vourcherNum}}</p>
          <button @click='discountcenter'>领券<svg class="icon"><use xlink:href="#iconziyuan12"></use></svg></button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  name: 'usercenterhd',
  props: {
    popData: Object
  },
  data(){
    return {
      that:this
    }
  },
  methods:{
    linkuserdata(){
      this._router({then:()=>{
        this.$router.push('/userdata.html')
      },exp:true,catch:()=>{
        this.$router.push({path:'/accountlogin',query:{redirect:'/userdata.html'}})
        // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    },
    rechargelink(){
      // 充值跳转
      this._router({then:()=>{
        this.$router.push('/userrecharge.html')
      },exp:true,catch:()=>{
        this.$router.push({path:'/accountlogin',query:{redirect:'/userrecharge.html'}})
        // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    },
    discountcenter(){
      // 领券中心
      this._router({then:()=>{
        this.$router.push({path: "/discountcenter.html"})
      },exp:true,catch:()=>{
        this.$router.push({path:'/accountlogin',query:{redirect:'/discountcenter.html'}})
        // this._open({path: "/accountlogin",query: {redirect:'/'}})
      }})
    },
  },
  mounted(){
  },
  filters:{
    imgsrcs(v,that){
      return that._src + v
    },
    timereset(v){
      if(v){
        return v.split('T')[0]+' '+v.split('T')[1]
      }
    }
  }
}
</script>
<style  lang="scss" scoped>
.usercenterhd {
  width: 100%;background: #fff;height: 200px;
  .el-row {
    .lt {
      display: flex;justify-content: center;align-items: center;height: 200px;position: relative;
      .img {
        position: relative;
        .boxF, .boxS, .boxT{
            width: 120px;
            height: 150px;
            overflow: hidden;
        }
        .boxF, .boxS{
            visibility: hidden;
        }
        .boxF{
            transform: rotate(120deg);
            float: left;
            margin-left: 10px;
            -ms-transform: rotate(120deg);
            -moz-transform: rotate(120deg);
            -webkit-transform: rotate(120deg);
            .boxS{
              transform: rotate(-60deg);
              -ms-transform: rotate(-60deg);
              -moz-transform: rotate(-60deg);
              -webkit-transform: rotate(-60deg);
              .boxT{
                  transform: rotate(-60deg);
                  background:#d5d8dd no-repeat 50% center;
                  background-size: 125% auto;
                  -ms-transform: rotate(-60deg);
                  -moz-transform: rotate(-60deg);
                  -webkit-transform: rotate(-60deg);
                  visibility: visible;
              }
          }
        }
        .boxF1, .boxS1, .boxT1{
            width: 110px;
            height: 137.5px;
            overflow: hidden;
        }
        .boxF1{
            margin-left:5px;margin-top:6px;
            .boxS1{
              .boxT1{
                  img {
                    display: block;width: 130px;height: 130px;margin-left:-11px;margin-top:4px;
                  }
                  svg {
                     display: block;width: 80px;height: 80px;margin-left:15px;margin-top:26px;fill: #ed008c;
                  }
              }
          }
        }
        .name {
          position: absolute;bottom:15px;left:-5px;
          svg {
            width: 150px;height: 30px;
          }
          span {
            position: absolute;display: block;width: 150px;font-size: 14px;bottom:0px;left:0px;font-weight: bold;text-align: center;height: 30px;line-height: 23px;
          }
        }
      }
      .info {
        margin-left:30px;
        h6 {
          font-size: 16px;color:#333;
        }
        p {
          color:#828282;font-size: 14px;height: 30px;line-height: 30px;
        }
        button {
          background-image: linear-gradient(to right, #ff4751 , #fc3f69);color:#fff;width: 146px;height: 26px;line-height: 26px;text-align: center;border-radius: 5px;margin-top:10px;
          svg {
            fill: #fff;width: 10px;vertical-align: -3px;
          }
          &:hover {
            opacity: 0.8;
          }
        }
      }
      &:after {
        content: '';display: block;width: 1px; height: 100px;background: #dfdede;top:50px;right:0px;position: absolute;
      }
    }
    .ct {
      display: flex;justify-content: center;align-items: center;height: 200px;position: relative;
      .info {
        text-align: center;
        h6 {
          font-size: 16px;color:#333;
        }
        p {
          font-size: 22px;font-weight: bold;color:#fa6064;height: 40px;line-height: 40px;margin-bottom:10px;
        }
        button {
          background: none;color:#3c66a8;font-size: 14px;
          svg {
            width: 10px;vertical-align: -3px;fill:#3c66a8;margin-left:2px;
          }
          &:hover {
            opacity: 0.8;
          }
        }
      }
      &:after {
        content: '';display: block;width: 1px; height: 100px;background: #dfdede;top:50px;right:0px;position: absolute;
      }
    }
    .rt {
      display: flex;justify-content: center;align-items: center;height: 200px;
      .info {
        text-align: center;
        h6 {
          font-size: 16px;color:#333;
        }
        p {
          font-size: 22px;font-weight: bold;color:#3691cb;height: 40px;line-height: 40px;margin-bottom:10px;
        }
        button {
          background: none;color:#3c66a8;font-size: 14px;
          svg {
            width: 10px;vertical-align: -3px;fill:#3c66a8;margin-left:2px;
          }
           &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}
</style>
