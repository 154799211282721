<template>
  <div class="myordermenu">
    <el-row>
      <el-col :span="6">
        <button :disabled='orderDs' :class='{choices:choices==1}' @click='choiceTp(1)'><svg class="icon"><use xlink:href="#icondaifukuan-wodedingdan"></use></svg>待付款
        <i v-show='waits'>{{waits}}</i>
        </button>
      </el-col>
      <el-col :span="6">
        <button :disabled='orderDs' :class='{choices:choices==2}'  @click='choiceTp(2)'><svg class="icon"><use xlink:href="#icondengdaishouhuo"></use></svg>待收货<i v-show='waits1'>{{waits1}}</i></button>
      </el-col>
      <el-col :span="6">
        <button :disabled='orderDs' :class='{choices:choices==3}'  @click='choiceTp(3)'><svg class="icon"><use xlink:href="#icondaipingjia-wodedingdan"></use></svg>待评价<i v-show='waits2'>{{waits2}}</i></button>
      </el-col>
      <el-col :span="6">
        <button :disabled='orderDs' :class='{choices:choices==5}'  @click='choiceTp(5)'><svg class="icon"><use xlink:href="#icontuihuanshouhou-wodedingdan"></use></svg>退换/售后<i v-if='false'>4</i></button>
      </el-col>
      <el-col :span="6">
        <button @click='allorder'><span><svg class="icon"><use xlink:href="#iconziyuan4"></use></svg></span>全部订单</button>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  name: 'myordermenu',
  props: {
    msg: String,
    waits:Number,
    waits1:Number,
    waits2:Number,
  },
  data(){
    return {
      choices:0,
      orderDs:false
    }
  },
  methods:{
    allorder(){
      var _obj = {
        orderTypes:0,
        orderNumbers:'',
        pageIndexs:1
      }
      window.sessionStorage.setItem('ordersearchdataRe',JSON.stringify(_obj))
      // 全部订单
      this._router({then:()=>{
        this.$router.push('/usercenterorder.html')
      },exp:true,catch:()=>{
          var pms = '/usercenterorder.html'
          this.$router.push({path:'/accountlogin',query:{redirect:pms}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
    },
    choiceTp(v){
      var _obj = {
        orderTypes:v,
        orderNumbers:'',
        pageIndexs:1
      }
      window.sessionStorage.setItem('ordersearchdataRe',JSON.stringify(_obj))
      this._router({then:()=>{
        this.$router.push('/usercenterorder.html')
      },exp:true,catch:()=>{
          var pms = '/usercenterorder.html'
          this.$router.push({path:'/accountlogin',query:{redirect:pms}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
      // this.choices = v
      // this.orderDs= true
      // this.$emit('exportTypes',v)
    }
  },
  mounted(){
    var _userOrderlist = JSON.parse(window.sessionStorage.getItem('userOrderlist'))
    if(_userOrderlist){
      this.choices = _userOrderlist.orderTypes
    }
  },
  watch:{
    '$store.state.orderTypeClick'(n,o){
      this.orderDs = false
    }
  }
}
</script>
<style  lang="scss" scoped>
.myordermenu {
  height: 118px;background: #fff;margin-bottom:1px;
  .el-row {
    .el-col-6 {
      width: 20%;display: flex;justify-content: center;align-items: center;height: 118px;
      button {
        background: none;width: 89px;text-align: center;font-size: 14px;color:#333;position: relative;
        svg {
          display: block;margin:0 auto;width: 50px;height: 50px;margin-bottom:5px;
        }
        i {
          width: 20px;height: 20px;background: #e51c23;font-style: normal;color:#fff;display: block;line-height: 20px;text-align: center;border-radius: 50%;font-size: 12px;position: absolute;top:2px;right:12px;
        }
        &:last-child {
          span {
            background: #eeeeee;width: 45px;height: 45px;display: block;margin:0 auto;display: flex;justify-content: center;align-items: center;border-radius: 50%;margin-bottom:8px;
            svg {
              width: 16px;height: 16px;margin:0px;fill: #a0a0a0;
            }
          }
        }
        &:hover {
          opacity: 0.6;
        }
      }
      .choices {
        color:red;
      }
    }
  }
}
</style>
