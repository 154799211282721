<!-- 个人中心---个人信息 -->
<template>
  <div class="usercenter">
    <usercenterhr class='usercenterhr'></usercenterhr>
    <usercenterlt @exportUserData='exportUserData' class='usercenterlt' :nav='0'></usercenterlt>
    <usercenterhd v-if='userData' :popData='userData' class='usercenterhd'></usercenterhd>
    <div class="rtct">
      <div class='divcnt'>
        <usercentertitle title='我的订单'></usercentertitle>
        <myordermenu @exportTypes='exportTypes' :waits='waits' :waits1='waits1' :waits2='waits2'></myordermenu>
        <myorderlist v-if='orderData' :popdata='orderData'></myorderlist>
      </div>
      <div class="cntrt">
        <usercentertitle title='浏览记录'></usercentertitle>
      <browserecord v-if='browserecordData' :popData='browserecordData'></browserecord>
        <div class="browserecordempty" v-if='!browserecordData||browserecordData.length<1'>暂无记录</div>
        <usercentertitle title='常购清单' ></usercentertitle>
        <oftenbuy  v-if='oftenbuyData'  :popData='oftenbuyData'></oftenbuy>
        <div class='oftenbuyempty' v-if='!oftenbuyData||oftenbuyData.length<1'>暂无记录</div>
      </div>
    </div>
  </div>
</template>
<script>
import usercenterhr from '@/components/usercenter-hr/usercenter-hr.vue';//顶部hr
import usercenterlt from '@/components/usercenter-lt/usercenter-lt.vue';//左侧栏目
import usercenterhd from '@/components/usercenter-hd/usercenter-hd.vue';//右侧头部
import usercentertitle from '@/components/usercenter-title/usercenter-title.vue';//内容标题
import myordermenu from '@/components/myorder-menu/myorder-menu.vue';//我的订单-按钮组
import myorderlist from '@/components/myorder-list/myorder-list.vue';//我的订单-个人中心列表
import browserecord from '@/components/browse-record/browse-record.vue';//浏览记录
import oftenbuy from '@/components/often-buy/often-buy.vue';//常购清单
export default {
  name: 'usercenter',
  components: {
    usercenterhr,
    usercenterlt,
    usercenterhd,
    usercentertitle,
    myordermenu,
    myorderlist,
    browserecord,
    oftenbuy
  },
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
        name: "description",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoDescrition
      },
      {
        name: "Keywords",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoKey
      }
        ]
    }
  },
  data(){
    return {
      htmltitle:'个人中心-微微定',
      userData:null,
      orderTypes:1,
      orderData:null,
      waits:null,
      waits1:null,
      waits2:null,
      browserecordData:null,//浏览记录数组
      oftenbuyData:null,//常购记录
    }
  },
  props: {
    msg: String
  },
  methods:{
    exportUserData(v){
      // 用户信息
      this.userData = v.customer;
      this.browserecordData = v.HistoryList;
      this.oftenbuyData = v.buyList;
    },
    getData(param){
      var CustomerID='';
      if(this.$store.state.user){
        if(this.$store.state.user.CustomerID){
          CustomerID = this.$store.state.user.CustomerID
        }
      }
      // 请求数据
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/MyOrderList?OrderStaus='+param.types+'&SearchName='+param.number+'&pageIndex='+param.indexs+'&pageSize='+param.sizes,this.keys({token:this._token({method:'get'})}))
        .then(res => {
            if(res.data.Success){
              this.$store.dispatch("cmtorderTypeClick",this.$store.state.orderTypeClick+=1)
              if(param.fn){
                param.fn(res)
              }
            }
          }, res => {
            console.log('系统异常')
          })
      },exp:true,catch:()=>{
          this.$router.push({path:'/accountlogin',query:{redirect:'/usercenter.html'}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
    },
    resultGet(){
      // var _userOrderlist = JSON.parse(window.sessionStorage.getItem('userOrderlist'))
      // if(_userOrderlist){
      //   this.orderTypes = _userOrderlist.orderTypes
      // }
      this.getData({types:0,number:'',indexs:1,sizes:5,fn:(res)=>{
        // console.log(res)
        if(res.data.Data){
          this.orderData = res.data.Data.list.List
          this.waits = res.data.Data.waitingpayNum
          this.waits1 = res.data.Data.waitingreceiptNum
          this.waits2 = res.data.Data.waitevaluationNum
        }
      }})
    },
    exportTypes(v){
      // 分类跳转
      this.orderTypes = v
      this.getData({types:this.orderTypes,number:'',indexs:1,sizes:5,fn:(res)=>{
        // console.log(res)
        if(res.data.Data){
          this.orderData = res.data.Data.list.List
          this.waits = res.data.Data.waitingpayNum
          this.waits1 = res.data.Data.waitingreceiptNum
          this.waits2 = res.data.Data.waitevaluationNum
          var _obj = {
            orderTypes:this.orderTypes,
          }
          window.sessionStorage.setItem('userOrderlist',JSON.stringify(_obj))
        }
      }})
    },
  },
  mounted(){
    this.$nextTick(()=>{
      // 加载
      var _this = this;
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.a,_pomise.b])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        this.resultGet()
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
    })
  }
}
</script>
<style  lang="scss" scoped>
.usercenter {
  width: 1200px;margin:0 auto;zoom:1;padding-bottom:20px;
  .usercenterhr {
    width: 1200px;margin:0 auto;margin-top:20px;margin-bottom:10px;position: relative;z-index: 9;
  }
  .usercenterlt {
    float:left;width: 180px;margin-top:-10px;
  }
  .usercenterhd {
    width: 1010px;float:right;margin-top:-10px;
  }
  .rtct {
    width: 1010px;margin-top:10px;float:right;
    .divcnt {
      float:left;width: 700px;
    }
    .cntrt {
      float:right;width: 300px;
      .browserecordempty {
        background: #fff;padding:20px;margin-bottom:10px;font-size: 14px;color:#797979;
      }
      .oftenbuyempty {
        background: #fff;padding:20px;margin-bottom:10px;font-size: 14px;color:#797979;
      }
    }
  }
  &:after {
    content: "";display: block;clear:both;
  }
}
</style>
