<template>
  <div class="myorderlist">
    <div v-for='item,index in popdata' :key='index' class="item" >
      <div class="lt">
        <div class="img">
          <img v-if='item&&item.orderDeailJobList.length>0' :src="item.orderDeailJobList[0].ImageUrl | imgreset(that)">
        </div>
        <div class="info">
          <h6  v-if='item.PayStatusID==0&&item.StatusID!=7'>您提交了订单，等待付款</h6>
          <h6  v-else-if='item.StatusID<2'>订单已支付，等待收货</h6>
          <h6  v-else-if='item.StatusID==2'>订单已完成</h6>
          <h6  v-else-if='item.StatusID==7'>订单已取消</h6>
          <h6  v-else-if='item.StatusID==8||item.StatusID==9'>订单已退款</h6>
          <p v-if='item.PayStatusID==0' class="pr" v-show='true'>&yen;{{item.PayAmount}}</p>
          <p v-if='item.PayStatusID!=0' class="pr" v-show='true'>&yen;{{item.PayedAmount}}</p>
          <p v-if='item.PayStatusID==0&&item.StatusID!=7' class="dr" v-show='true'>下单时间 | {{item.OrderDate?item.OrderDate:'' | timereset}}</p>
          <p v-else-if='item.StatusID<2' class="dr" v-show='true'>支付时间 | {{item.PayDate?item.PayDate:'' | timereset}}</p>
          <p v-else-if='item.StatusID==8||item.StatusID==9' class="dr" v-show='true'>退款时间 | {{item.ReturnDate?item.ReturnDate:'' | timereset}}</p>
        </div>
      </div>
      <div class="rt1">
        <el-popover
          v-if='item.StatusID<2&&item.PayStatusID!=0'
          placement="left"
          trigger="hover">
          <div class="cntbox-logisticsinfo">
            <!-- <h6 class="h6">普通快递</h6> -->
            <!-- <el-timeline>
              <el-timeline-item
                v-for="(activity, index) in activities"
                :key="index"
                :timestamp="activity.timestamp">
                {{activity.content}}
              </el-timeline-item>
            </el-timeline> -->
            <el-timeline>
              <!-- <el-timeline-item>
                <div class="info">
                  <h6>已签收<p><span>2019-03-15/周五</span><span class='sp'>17:41:44</span></p><svg><use xlink:href="#iconyiqianshou"></use></svg></h6>
                  <p class="p">您的订单已由本人签收，感谢您在京东购物，欢迎再次光临。</p>
                </div>
              </el-timeline-item>
              <el-timeline-item>
                <div class="info">
                  <h6>派送中<p><span>2019-03-15/周五</span><span class='sp'>17:41:44</span></p><svg><use xlink:href="#iconpaisongzhong"></use></svg></h6>
                  <p class="p">您的订单正在配送途中（配送员：李鹏飞，电话：18021662）请您准备签收，感谢您的耐心等待</p>
                </div>
              </el-timeline-item> -->
              <el-timeline-item v-if='logisticsData&&logisticsData.TracesList.length>0'>
                <div class="info">
                  <h6>{{logisticsData.state!=3?'运输中':'已签收'}}<p><span>{{logisticsData.TracesList[logisticsData.TracesList.length-1].AcceptTime | getTimet2}}</span><span class='sp'>{{ logisticsData.TracesList[logisticsData.TracesList.length-1].AcceptTime | getTimet3}}</span></p>
                    <svg v-show='logisticsData.state!=3'><use xlink:href="#iconyunshuzhong1"></use></svg>
                    <svg v-show='logisticsData.state==3'><use xlink:href="#iconyiqianshou"></use></svg>
                    <span class="svgs"></span>
                  </h6>
                  <!-- <p class="p" v-for='items,indexs in logisticsData.TracesList' :key='indexs'>您的订单已进入第三方卖家仓库，准备出库</p> -->
                  <!-- <p class="p">您的订单已进入第三方卖家仓库，准备出库<span class='sp'>17:41:44</span></p> -->
                  <p class="p" v-for='items,indexs in logisticsData.TracesList' :key='indexs'>{{items.AcceptStation}}<span v-show='indexs!=0' class='sp'>{{items.AcceptTime | getTimet3}}</span></p>
                </div>
              </el-timeline-item>
           <!--    <el-timeline-item>
                <div class="info">
                  <h6>仓库处理中<p><span>{{item.DeliveryDate | getTimet1}}</span><span class='sp'>{{item.DeliveryDate | getTimet}}</span></p><svg><use xlink:href="#iconcangkuchulizhong"></use></svg></h6>
                  <p class="p">您的订单已进入第三方卖家仓库，准备出库</p>
                </div>
              </el-timeline-item> -->
              <el-timeline-item v-if='logisticsData1&&logisticsData1.length>0'>
                <div class="info">
                  <h6 >仓库处理中<p><span>{{logisticsData1[logisticsData1.length-1].OperateDate | getTimet1}}</span><span class="sp">{{logisticsData1[logisticsData1.length-1].OperateDate | getTimet4}}</span></p><svg><use xlink:href="#iconcangkuchulizhong"></use></svg><span class="svgs"></span></h6>
   <!--                <h6 v-if='logisticsData1&&logisticsData1.length>0'>仓库处理中<p><span>{{logisticsData1[logisticsData1.length-1].OperateDate | getTimet1}}</span><span class='sp'>{{logisticsData1[logisticsData1.length-1].OperateDate | getTimet3}}</span></p><svg><use xlink:href="#iconcangkuchulizhong"></use></svg></h6> -->
                  <p class="p" v-for='items,indexs in logisticsData1' :key='indexs'>{{items.msg}}<span v-show='indexs!=0'  class='sp'>{{items.OperateDate | getTimet4}}</span></p>
                </div>
              </el-timeline-item>
              <el-timeline-item >
                <div class="info">
                  <h6>已下单<p><span>{{item.OrderDate | getTimet1}}</span><span class="sp">{{item.OrderDate | getTimet}}</span></p><svg><use xlink:href="#iconyixiadan"></use></svg><span class="svgs"></span></h6>
                  <p class="p">您提交了订单，请等待系统确认</p>
                </div>
              </el-timeline-item>
            </el-timeline>
          </div>
          <p class="btnp" @mouseenter='mouseenter(item)' @mouseleave='mouseleave' slot="reference">物流跟踪</p>
        </el-popover>
      </div>
      <div class="rt">
        <button @click='linkdetail(item)'>查看详情</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'myorderlist',
  props: {
    popdata: Array
  },
  data(){
    return {
      activities: [{
        content: '货物已由厂家自送，请您准备收货',
        timestamp: '2018-04-15 08:35:35',
        size:'normal',
      }, {
        content: '第三方卖家已经开始拣货',
        timestamp: '2018-04-13 08:35:35',
        size:'normal',
      }, {
        content: '您的订单已进入第三方卖家仓库，准备出库',
        timestamp: '2018-04-11 08:35:35',
        size:'normal'
      }, {
        content: '您提交了订单，请等待第三方卖家系统确认',
        timestamp: '2018-04-11 08:35:35',
        size:'normal'
      }],
      that:this,
      logisticsData:null,//物流信息
      logisticsData1:null,//物流信息
    }
  },
  methods:{
    linkdetail(item){
      // 跳转订单详情
      this._router({then:()=>{
        this.$router.push({path:'/orderdetail',query:{OrderName:item.OrderName+'.html'}})
      },exp:true,catch:()=>{
          // OrderName=2005191026597231&StatusID=0&PayStatusID=0&ISDelivery=0
          var pms  = '/orderdetail?OrderName='+item.OrderName + '.html'
          this.$router.push({path:'/accountlogin',query:{redirect:pms}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
    },
    mouseenter(item){
      // 打开物流
      // console.log(1)
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/ObtainTraceInfoByOrderName?OrderName='+item.OrderName,this.keys())
        .then(res => {
            if(res.data.Success){
              this.logisticsData = res.data.Data
            }
          }, res => {
            console.log('物流请求失败')
          })
      },exp:true,catch:()=>{
          var pms = '/usercenter.html'
          this.$router.push({path:'/accountlogin',query:{redirect:pms}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'UserCenter/ObtainOrderStatusLog?OrderName='+item.OrderName,this.keys())
        .then(res => {
            if(res.data.Success){
              this.logisticsData1 = res.data.Data
            }
          }, res => {
            console.log('物流请求失败')
          })
      },exp:true,catch:()=>{
          var pms = '/usercenter.html'
          this.$router.push({path:'/accountlogin',query:{redirect:pms}})
          // this._open({path: "/accountlogin",query: {redirect:'/'}})
        }})
    },
    mouseleave(){
      //关闭物流
      // console.log(2)
    }
  },
  mounted(){
  },
  filters:{
    imgreset(v,that){
      return that._src +v
    },
    timereset(v){
      if(v){
        return v.split('T')[0] + ' '+v.split('T')[1]
      }else{
        return ''
      }
    },
    getTimet(v){
      // 获取时分秒
      if(v){
        return v.split('T')[1]
      }else{
        return '';
      }
    },
    getTimet1(v){
      // 获取日期和星期
      if(v){
        var _dae = v.split('T')[0];
        var _days = ['周日','周一','周二','周三','周四','周五','周六'][new Date(v).getDay()];
        return v.split('T')[0]+'/'+_days
      }else{
        return '';
      }
    },
    getTimet2(v){
      // 物流-获取日期和星期
      if(v){
        var _dae = v.split(' ')[0];
        var _days = ['周日','周一','周二','周三','周四','周五','周六'][new Date(v).getDay()];
        return v.split(' ')[0]+'/'+_days
      }else{
        return '';
      }
    },
    getTimet3(v){
      // 物流-获取时分秒
      if(v){
        return v.split(' ')[1]
      }else{
        return '';
      }
    },
    getTimet4(v){
      // 物流-获取时分秒
      if(v){
        return v.split('T')[1]
      }else{
        return '';
      }
    },
  }
}
</script>
<style lang="scss">
  .cntbox-logisticsinfo {
      padding:20px 0;padding-left:190px;min-height: 20px;max-height: 250px;overflow-y: auto;
      .info {
        h6 {
          position: relative;font-size: 12px;margin-bottom:5px;padding-top:4px;
          p {
            position: absolute;top:5px;left:-215px;font-weight: normal;font-size: 12px;text-align: right;width: 170px;
            span {
              font-size: 12px;
            }
            .sp {
              margin-left:15px;font-size: 12px;
            }
          }
          svg {
            fill: #797979;
            width: 24px;height: 24px;position: absolute;top:0px;left:-35px;
          }
          .svgs {
            width: 24px;height: 24px;position: absolute;top:0px;left:-35px;z-index: 9;background: #fff;
          }
        }
        .p {
          font-size: 12px;margin-bottom:10px;position: relative;
          .sp {
            position: absolute;top:0px;left:-93px;width: 60px;display: block;font-size: 12px;
          }
        }
      }
      /deep/.el-timeline {
        .el-timeline-item {
          .el-timeline-item__node {
            width: 20px;height: 20px;left:-5px;display: none;
          }
          &:first-child {
            // .el-timeline-item__node {
            //   background: red;
            // }
            .info {
              h6 {
                svg {
                  fill: red;
                }
              }
            }
          }
        }
      }
      // /deep/.el-timeline {
      //   .el-timeline-item {
      //     &:first-child {
      //       .el-timeline-item__node {
      //         width: 18px;height: 18px;left: -4px;
      //       }
      //       &:after {
      //         content: '';display: block;position: absolute;width: 4px;height:4px;border-radius: 50%;background: #e2231a;top:7px;left:3px;z-index: 999;box-shadow: 0px 0px 7px 5px rgba(226,35,26,0.4);
      //       }
      //     }
      //   }
      // }
      // .h6 {
      //   font-size: 14px;color:#333;border-bottom:1px solid #e3e3e3;height: 30px;line-height: 30px;margin-bottom:15px;
      // }
    }
</style>
<style  lang="scss" scoped>
.myorderlist {
  .item {
    zoom:1;background: #fff;height: 97px;margin-bottom:1px;
    .lt {
      float:left;height: 97px;display: flex;justify-content: center;align-items: center;
      .img {
        margin-left:20px;
        img {
          display: block;width: 60px;height: 60px;
        }
      }
      .info {
        margin-left:20px;
        .h6 {
          color:#626264;font-size: 14px;font-weight: normal;
        }
        .pr {
          color:#e51c23;font-size: 14px;margin-top:5px;
        }
        .dr {
          color:#626264;font-size: 14px;margin-top:5px;
        }
      }
    }
    .rt,.rt1 {
      float:right;height: 97px;display: flex;justify-content: center;align-items: center;
    }
    .rt {
      button {
        background: none;margin-right: 30px;position: relative;color:#333;
        &:after {
          content: '';display: block;position: absolute;width: 1px;height: 10px;top:6px;right:-16px;background: #e2e2e2;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .rt1 {
      .btnp {
        margin-right: 35px;color:#333;cursor: pointer;
         &:hover {
          opacity: 0.8;
        }
      }
    }
    &:after {
      content: '';display: block;clear:both;
    }
  }
}
</style>
