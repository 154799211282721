<template>
  <div class="browserecord" v-if='localData&&localData.length>0'>
    <button @click='back' class="back"><svg class="icon"><use xlink:href="#iconziyuan2"></use></svg></button>
    <button @click='next' class="next"><svg class="icon"><use xlink:href="#iconziyuan4"></use></svg></button>
    <div class="box">
      <div class="imgs" :style='{width:localData.length*65+"px",left:left+"px"}'>
        <img @click='linkDetail(item)' :src="item.ImagePath | imgsrc(that)" v-for='item,index in localData'>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'browserecord',
  data (){
    return {
      left:0,
      length:10,
      localData:null,
      that:this
    }
  },
  props: {
    popData: Array,
  },
  methods:{
    linkDetail(item){
      // 跳转商品详情
      // console.log(item)
      this._router({then:()=>{
        // this.$router.push({path:'predetail',query:{id:it.Id,idcx:'',detailType:1}})
        var id = item.ProductTypeId + '.html'
        this._open({path:'/predetail',query:{idcx:item.DynamicId?item.DynamicId:'',id:id,}})
      }})
    },
    back(){
      // 后退
      if(this.left<0){
        this.left +=180
        if(this.left>0){
          this.left = 0
        }
      }
    },
    next(){
      // 前进
      if(this.left>-(this.localData.length*65-260)){
        this.left-=180
        if(this.left<-(this.localData.length*65-260)){
          this.left=-(this.localData. length*65-260)
        }
      }
    }
  },
  mounted(){
    this.$nextTick(()=>{
      this.localData = this.popData
    })
  },
  watch:{
    'popData'(n,o){
      this.localData = n
    }
  },
  filters:{
    imgsrc(v,that){
      return that._src+ v
    }
  }
}
</script>
<style  lang="scss" scoped>
.browserecord {
  background: #fff;height: 120px;position: relative;margin-bottom:10px;
  .box {
    width: 260px;height: 60px;margin:0 auto;overflow: hidden;position: absolute;top:28px;left:20px;
    .imgs {
      position: absolute;left:0px;transition: left 0.5s;
      img {
        display: block;width: 60px;height: 60px;float:left;margin-right: 5px;cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .back,.next {
    position: absolute;top:40px;width: 20px;height: 38px;line-height: 38px;background: #d6d6d6;
    svg {
      width:12px;height: 12px;vertical-align: -1px;fill: #8f8f8f;
    }
  }
  .back {
    left:0px;
  }
  .next {
    right:0px;
  }
}
</style>
